.test-mode-toggle-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 8px;
}

.test-mode-toggle-message {
  font-size: 13px;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  background-color: var(--dark-gray) !important;
}

.test-mode-switch {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0 15px;
  justify-content: space-between;
}
