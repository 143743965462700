.table-container {
  background-color: #fff;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  margin-bottom: 10px;
  overflow: hidden;
  max-height: 74vh;
}

.table-container:has(table) th {
  position: sticky;
  top: 0;
  z-index: 1;
}

.table-block {
  width: 100%;
  overflow-x: auto;
  position: relative;
  max-height: 74vh;
}

.table-docked-controls {
  max-height: 81vh;
}

.table-docked-controls .table-block {
  max-height: 81vh;
  @media screen and (max-width: 780px) {
    max-height: 71vh;
  }
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

.table-container th:has(.react-datepicker-wrapper) {
  min-width: 180px;
}

.table-container th {
  background-color: #e3e9ef;
  padding: 10px;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
  vertical-align: bottom;
}

.table-container .table-title {
  display: block;
  margin-bottom: 5px;
}

.table-container th:focus-within .table-title {
  color: var(--accent);
}

.table-container td {
  text-align: center;
  padding: 8px 15px;
  border-bottom: 1px solid #e3e9ef;
}

.table-container tr:last-child > td {
  border-bottom: 1px solid #eee;
}

.table-container th.hidden-column,
.table-container td.hidden-column {
  display: none;
}

.table-container tr:hover td {
  background-color: #f3f5f9;
}

.table-container td .MuiIconButton-root svg {
  font-size: 20px;
}

.table-container td .MuiIconButton-root:hover {
  color: var(--accent);
}

.table-horizontal th {
  text-align: left;
  border-bottom: 1px solid #fff;
  width: 30%;
}

.table-horizontal td {
  text-align: left;
}

.table-column__name {
  display: none;
  color: var(--text-color-muted);
}

.table-actions {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.table-actions-item:only-child {
  margin-left: auto;
}

.table-actions-item form {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

.table-actions-item .form-item {
  flex-shrink: 0;
  margin-bottom: 0;
}

.table-actions-item .form-item label {
  background: none;
  top: auto;
  bottom: 100%;
  z-index: auto;
}

.table-actions-item__cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.settlements-widget .table-actions-item:first-child {
  min-width: 25%;
}

.table-container .react-datepicker__input-container input {
  min-width: 150px;
  font-size: 11px;
}

.table-container .react-datepicker__input-container input::placeholder {
  font-size: var(--font-size);
}

.table-container .react-datepicker__close-icon::after {
  background-color: var(--accent);
}

.table-container
  .react-datepicker__day--in-range:not(
    .react-datepicker__day--range-start,
    .react-datepicker__day--range-end
  ) {
  background-color: var(--accent);
}

.btn.thead-toggler {
  display: none;
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  background: #fff;
  border-color: #fff;
  color: var(--text-color);
  box-shadow: var(--shadow);
}

.bulk-btns {
  display: flex;
  gap: 10px;
}

.bulk-btns button {
  padding: 9px 15px;
}

.bulk-btns button svg {
  margin-left: 10px;
}

.table-cell-logo {
  max-width: 60px;
  margin: 0 auto;
}

.table-cell-logo img {
  max-width: 100%;
  height: auto;
}

.table-cell-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media (max-width: 780px) {
  .table-container table thead {
    display: none;
  }
  .table-container table thead.thead-visible {
    display: block;
  }
  .btn.thead-toggler {
    display: flex;
  }
  .table-container table tr {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid var(--border-color-muted);
  }

  .table-container table tr th,
  .table-container table tr td {
    padding: 10px;
    flex-grow: 1;
    border-bottom: none;
    text-align: left;
    word-wrap: break-word;
  }

  .table-container table tr th {
    flex-wrap: wrap;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .table-container table tr th.hidden-on-mob,
  .table-container table tr td.hidden-on-mob {
    display: none;
  }

  .table-container .action-btns {
    width: 100%;
    justify-content: space-around;
  }

  .table-container table tr td.action-btns-row {
    display: none;
    width: 100%;
    padding-top: 0;
  }
  .table-container table tr:hover td.action-btns-row {
    display: flex;
  }

  .table-horizontal table {
    table-layout: fixed;
  }

  .table-horizontal th,
  .table-horizontal td {
    font-size: 12px;
    width: 50%;
    word-break: break-all;
  }

  .table-column__name {
    display: block;
  }

  .table-actions {
    flex-direction: column;
    gap: 10px;
  }

  .table-actions-item {
    width: 100%;
  }
  .table-actions-item .form-item {
    flex-grow: 1;
  }
  .table-actions-item .form-item label {
    display: block;
    position: static;
    padding: 5px 10px;
  }
  .table-actions-item button {
    width: 100%;
  }
  .table-actions-item__cell {
    flex-direction: column;
    align-items: inherit;
    gap: 5px;
  }

  .bulk-btns {
    flex-direction: column;
  }
}

.table-filter-input {
  min-width: 90px;
}

.table-filter-select-async,
.table-filter-select-sync {
  min-width: 170px;
}

.table-control-menu {
  position: relative;
}

.table-control-menu li,
button {
  display: flex;
  gap: 10px;
}

#table-control-menu button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 0;
  width: 100%;
  background: transparent;

  &:hover {
    background-color: transparent;
  }
}

.control-block {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding-bottom: 5px;
}

.control-block .MuiIconButton-root {
  background-color: white;
}

button.reset-filter-btn {
  background-color: white;
  min-width: 34px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 8px;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
}
