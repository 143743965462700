.navbar > ul > li {
  margin-bottom: 3px;
}

.navbar > ul > li.is-disabled,
.subnav li.is-disabled {
  opacity: 0.3;
}

.navbar ul li a,
.navbar ul li .nav-link {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  min-height: 36px;
  color: var(--light-gray);
  text-decoration: none;
}

.navbar > ul > li > a.active {
  background: var(--dark-gray-hover);
  color: var(--accent);
  border-radius: 8px;
}

.navbar > ul > li:hover > a,
.navbar > ul > li:hover > .nav-link {
  background: var(--dark-gray-hover);
  border-radius: 8px;
}

.navlink-txt {
  display: none;
  margin-left: 10px;
}

.sidebar__open .subnav {
  margin-left: 15px;
}

.subnav li a:hover,
.subnav li a.active {
  color: var(--accent);
}

.subnav li a.active::before {
  background: var(--accent);
  box-shadow: 0px 0px 0px 4px rgb(78, 151, 253, 20%);
}

.subnav li a::before {
  content: '';
  display: flex;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background: var(--light-gray);
  margin-right: 10px;
  margin-left: 10px;
}

.navbar .MuiAccordion-gutters.Mui-expanded {
  margin: 0;
}

.navbar .MuiAccordion-root {
  background: none;
  box-shadow: none;
  color: inherit;
}

.navbar .MuiAccordionSummary-root {
  padding: 0;
  min-height: auto;
}

.navbar .MuiAccordionSummary-root:hover {
  background: var(--dark-gray-hover);
  border-radius: 8px;
}

.navbar .MuiAccordionSummary-root.Mui-expanded {
  min-height: auto;
}

.navbar .MuiAccordionSummary-content {
  margin: 0;
}

.navbar .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.navbar .MuiAccordionSummary-expandIconWrapper {
  display: none;
  color: inherit;
  margin-right: 5px;
}

.sidebar__open .navbar .MuiAccordionSummary-expandIconWrapper {
  display: flex;
}

.navbar .MuiAccordionDetails-root {
  padding: 0;
}
