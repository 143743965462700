.pagination-container {
  background: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  overflow-x: auto;
  padding: 5px 0;
}

.pagination,
.pagination-changer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.pagination-changer .page-item {
  width: 40px;
  height: 40px;
}

.page-item {
  min-width: 30px;
  height: 30px;
  line-height: 1;
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 30px;
  cursor: pointer;
  padding: 0 5px;
}

.page-item svg {
  font-size: 18px;
}

.page-item.is-active,
.page-item:hover {
  border-color: var(--accent);
  color: var(--accent);
}

.page-item.is-disabled {
  opacity: 0.3;
  pointer-events: none;
}

.pagination-counter {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 8px;
  background: #f2f2f2;
  border-radius: 10px;
  padding: 6px 8px;
}
