.bank-settings-form {
  .form-row {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: flex-start;
    margin-bottom: 1rem;
  }

  .form-item {
    width: 100%;
  }

  .add-method {
    display: flex;
    justify-content: left;
    margin-bottom: 2rem;
  }
}
