.hotkeys-help {
  color: #333;
  h3 {
    padding-bottom: 10px;
  }
  > section {
    margin-bottom: 20px;
  }
}

.shortcuts-group {
  margin: 15px 0;
}

.shortcuts-group h4 {
  margin-bottom: 8px;
  color: #666;
}

.shortcuts-group ul {
  list-style: none;
  padding-left: 15px;
  display: inline-flex;
  gap: 10px;
}

.shortcuts-group li {
  margin: 5px 0;
}

.examples li {
  padding-bottom: 10px;
}
