.dashboard-filter {
  display: flex;
  gap: 30px 20px;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-top: 20px;
}

.dashboard-filter__item {
  flex-grow: 1;
}

.dashboard-filter .form-item label {
  background: none;
  top: -20px;
  z-index: 0;
}

.dashboard-filter .form-field input {
  appearance: auto;
  -webkit-appearance: auto;
}

.dashboard-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 20px;
}

.dashboard-item {
  color: var(--text-color-muted);
}

.dashboard-item:first-child {
  grid-row: span 2;
  grid-column: span 2;
}

.dashboard-item:first-child .card {
  background: #fff url('../../assets/images/dashboard.svg') no-repeat right bottom;
  background-position: calc(100% - 20px) bottom;
}

.dashboard-item .card {
  height: 100%;
}

.dashboard-item__welcome {
  font-size: 16px;
  font-weight: 600;
  color: var(--accent);
  margin-bottom: 5px;
}

.dashboard-item__balance {
  margin-top: 25px;
}

.dashboard-item__columns {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.dashboard-item__details {
  flex-grow: 1;
}

.dashboard-item__chart {
  flex-shrink: 0;
}

.dashboard-item__title {
  font-weight: 600;
  margin-bottom: 10px;
}

.dashboard-item__amount {
  font-size: 20px;
  color: var(--text-color);
  font-weight: 700;
  margin-bottom: 5px;
}

.dashboard-item__count {
  color: var(--text-color-soft);
}

@media (max-width: 780px) {
  .dashboard-filter {
    padding-top: 10px;
  }
  .dashboard-filter button {
    width: 100%;
  }
  .dashboard-filter .select__multi-value__label {
    white-space: initial;
  }
  .dashboard-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
