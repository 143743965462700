.data-table-view {
  --color-id: #ffa5a5; /* Lighter Coral */
  --color-status: #b3a5d6; /* Lighter Purple Haze */
  --color-status-reason: #c4db89; /* Lighter Greenery */
  --color-created-at: #ffe5e8; /* Lighter Rose Quartz */
  --color-request-log: #c4d6f0; /* Lighter Serenity */
  --color-order: #ffb3a5; /* Lighter Peach Echo */
  --color-transaction: #ffc4e6; /* Lighter Island Paradise */
  --color-decisions: #ffd699; /* Lighter Sunburst */
  --color-parser-tasks: #e6b3d9; /* Lighter Lavender */
  --color-stored-events: #ffe699; /* Lighter Buttercup */
  --color-audits: #4cd9c3; /* Lighter Teal */
  --color-id-auto: #ffd6d6; /* Lighter Dusty Rose */
  --color-step: #ffb3a5; /* Lighter Watermelon */
  --color-wallet-id: #c4e6e0; /* Lighter Mint */
  --color-attempts: #ffc4b3; /* Lighter Salmon Pink */
  --color-credentials-hash: #ff9999; /* Lighter Red Clay */
  --color-updated-at: #fff0b3; /* Lighter Khaki */
  --color-deleted-at: #c4e6f0; /* Lighter Powder Blue */
  --color-file-path: #ffb399; /* Lighter Coral Reef */
  --color-additional: #89c4d6; /* Lighter Steel Blue */
  --color-wallet: #ffc4d1; /* Lighter Light Pink */
  --color-request-ids: #b3d9b3; /* Lighter Dark Sea Green */
  --color-parser-task_results: #e6c4e6; /* Lighter Plum */
}

.data-table-view table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  border: 1px solid #dee2e6;
  font-size: 12px;
}

.data-table-view > table {
  margin: 20px 0;
}

.data-table-view table th,
.data-table-view table td {
  padding: 5px;
  border: 1px solid #dee2e6;
  word-break: break-word;
}

.data-table-view table th {
  text-align: right;
  white-space: nowrap;
}

.data-table-view table td:has(table) {
  padding: 2px;
}

.collapse-icon {
  padding-left: 7px;
}

.data-table-row {
  opacity: 0.9;
}

.data-table-row.nested-content {
  cursor: pointer;
}

.data-table-header {
  display: flex;
  align-items: center;
}

.nested-content-cell {
  padding: 0 16px 16px 16px;
}
