.connection-inactive,
.ar-low {
  color: #f00;
}

.connection-active,
.ar-high {
  color: #00e500;
}

.connection-warning {
  color: #ffd700;
}

.form-item:has(.get-wallet-credentials__btn) {
  grid-column: 1 / -1;
}

.get-wallet-credentials__btn {
  width: 100%;
  margin-top: 10px;
}

.create-wallet-mid-form .form-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 10px;
  margin-bottom: 20px;
}

.create-wallet-mid-form .form-row .form-item {
  flex: 1;
  margin: 0;
}

.add-wallet-mid-pair {
  margin-top: 40px;
  display: flex;
  gap: 20px;
  overflow: hidden;
}

.wallets-table table {
  table-layout: fixed;
}

.wallets-table table td {
  word-break: break-word;
}

.wallets-table table td:first-child:not(.empty) {
  text-align: left;
}

.wallets-table tr td[colspan]:not(.empty) {
  padding: 0;
}

.wallets-table .accordion-block {
  border: none;
  box-shadow: none;
}

.wallets-table .accordion-summary {
  padding: 0;
  padding-left: 30px;
  min-height: auto;
  position: relative;
  border-bottom: none;
}

.wallets-table .accordion-summary.Mui-expanded {
  min-height: auto;
}

.wallets-table .accordion-details {
  padding: 0;
}

.wallets-table .accordion-details table {
  border-top: 1px solid #f3f5f9;
}

.wallet-status-live,
.wallet-status-inactive {
  align-self: center;
  justify-self: center;
  padding: 3px 7px;
  font-size: small;
  border-radius: 5px;
}

.wallet-name-block {
  display: flex;
  align-items: center;
}

.wallet-name {
  font-size: large;
  font-weight: 700;
  margin-right: 10px;
}

.wallet-status-live {
  border: 1px solid rgb(22 163 74);
  background-color: rgb(187 247 208);
  color: rgb(22 163 74);
}

.wallet-status-inactive {
  background: #e3e9ef;
  border: 1px solid #979b9e;
}

.wallet-status__indicator {
  min-width: 22px;
  min-height: 22px;
  padding: 2px 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: rgb(22 163 74);
  background-color: rgb(187 247 208);
  border: 1px solid rgb(22 163 74);
  border-radius: 50%;
  vertical-align: top;
}

.wallet-name__filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loading-child-wallets {
  height: 55px;
}
.wallets-table .accordion-details tr td:first-child {
  position: relative;
  padding-left: 60px;
}

.wallets-table .MuiAccordionSummary-content,
.wallets-table .MuiAccordionSummary-content.Mui-expanded {
  margin: 0;
}

.wallets-table .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: -5px;
}

.wallets-table table tr {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(90px, 1fr));
  width: 100%;
}

.wallets-table table tr td,
.wallets-table table tr th {
  display: grid;
  align-items: center;
}

.wallets-table table tr td:first-child,
.wallets-table table tr th:first-child {
  grid-column: 1 / 3;
}
