.password-input-wrapper {
  position: relative;
  & input[type='password'],
  & input[type='text'] {
    padding-right: 80px;
  }
}

.password-toggle-button {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  right: 45px;
}
