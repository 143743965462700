.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.modal-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: #fff;
  border-radius: var(--radius);
  box-shadow: var(--shadow);
  padding: 30px;
  width: 100%;
  max-width: 1500px;
  max-height: 96%;
  overflow-y: auto;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 1;

  .table-columns-visibility {
    min-height: 300px;
  }
}
.modal-content:has(.request-info-container) {
  background-color: #fafafa;
}

.modal-content:last-child {
  opacity: 1;
  visibility: visible;
  position: relative;
}

.modal-content[data-size='sm'] {
  max-width: 400px;
}

.modal-content[data-size='md'] {
  max-width: 600px;
}

.modal-content[data-size='lg'] .popup-form,
.modal-content[data-size='md'] .popup-form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.modal-content[data-size='lg'] .popup-form .form-item,
.modal-content[data-size='md'] .popup-form .form-item {
  margin-bottom: 0;
}

.modal-content[data-size='lg'] .popup-form .form-submit,
.modal-content[data-size='md'] .popup-form .form-submit {
  grid-column: 1 / -1;
}

.modal-close {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  border-color: transparent;
  color: var(--text-color-muted);
  padding: 0;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 780px) {
  .modal-content {
    padding: 15px;
    max-height: 100%;
    overflow-y: auto;
  }
}
