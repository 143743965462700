.toggle-sidebar {
  background-color: #f6f9fc;
  width: 40px;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  border-radius: var(--radius);
  cursor: pointer;
}

.toggle-sidebar .secondary {
  opacity: 0.4;
}

@media (max-width: 1280px) {
  .toggle-sidebar {
    display: flex;
  }

  .toggle-sidebar.toggle-sidebar__open {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    z-index: 1;
  }

  .toggle-sidebar.toggle-sidebar__open svg {
    display: none;
  }
}
