header {
  background: #fff;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  box-shadow: 0px 4px 16px rgba(43, 52, 69, 0.1);
  position: sticky;
  top: 0;
  z-index: 6;
}

header h1 {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-right-block {
  display: flex;
  align-items: center;
  gap: 8px;
}

.date-time-block {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-time {
  min-width: 150px;
  text-align: right;
  white-space: nowrap;
}

@media (max-width: 780px) {
  header {
    padding: 10px;
    flex-wrap: wrap;
    gap: 10px;
  }
  header h1 {
    flex: 1;
    text-align: center;
  }
  .date-time-block {
    width: 100%;
    order: 10;
    gap: 10px;
  }
  .date-time {
    min-width: auto;
    white-space: normal;
    text-align: center;
  }
}
