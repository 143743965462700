.header-auth {
  position: relative;
}

.account-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: url('../../assets/images/user.svg') no-repeat center;
  cursor: pointer;
}

.account-pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.account-popup {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  width: 230px;
  margin-top: 10px;
  padding: 15px;
  background: #fff;
  border-radius: var(--radius);
  box-shadow: 0px 4px 16px rgba(43, 52, 69, 0.1);
  z-index: 2;
}

.account-popup:before {
  top: 0;
  right: 14px;
  z-index: 0;
  width: 10px;
  height: 10px;
  content: '';
  display: block;
  position: absolute;
  border-top: 1px solid;
  border-left: 1px solid;
  border-color: #f3f5f9;
  background-color: #fff;
  transform: translateY(-50%) rotate(45deg);
}

.account-popup-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.account-block.is-visible .account-popup,
.account-block.is-visible .account-popup-overlay {
  display: block;
}

.account-name {
  font-weight: 600;
}

.account-role,
.account-email {
  font-size: 12px;
  color: var(--text-color-soft);
  word-wrap: break-word;
}

.account-nav {
  margin: 10px -15px 0;
  padding-top: 10px;
  border-top: 1px dashed var(--border-color-muted);
}

.account-nav ul li a,
.account-logout {
  display: block;
  padding: 10px 15px;
  color: var(--text-color);
  text-decoration: none;
  cursor: pointer;
}

.account-nav ul li a:hover,
.account-logout:hover {
  background: #f3f5f9;
}

.account-nav ul li.account-logout-li {
  border-top: 1px dashed var(--border-color-muted);
  margin-top: 10px;
  margin-bottom: -15px;
}

.account-nav ul li.account-logout-li .account-logout {
  margin: 10px 0;
}
