.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.loading-container .loading {
  height: 4px;
}

.loading-default .loading-progress {
  height: 100%;
  background-color: var(--primary);
  animation: loading-fill 1.5s;
}

@keyframes loading-fill {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}

.loading-indeterminate .loading {
  background-color: rgba(var(--primary), 0.12);
  overflow: hidden;
  position: relative;
}

.loading-indeterminate .loading-progress {
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  animation: indeterminate 1.5s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminate {
  0% {
    transform: translateX(0) scaleX(0);
  }
  40% {
    transform: translateX(0) scaleX(0.4);
  }
  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
