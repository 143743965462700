@import url('https://fonts.googleapis.com/css2?family=Inter:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');

:root {
  --font: 'Inter', sans-serif;
  --font-size: 14px;
  --line-height: 1.286;
  --background: #f6f9fc;

  --primary: #d23f57;
  --accent: #4e97fd;
  --danger: #e94560;
  --success: #33d067;

  --text-color: #2b3445;
  --text-color-muted: #7d879c;
  --text-color-soft: #aeb4be;
  --border-color-muted: #e3e9ef;
  --radius: 8px;
  --shadow: 0px 1px 3px rgba(3, 0, 71, 0.09);
  --transition: all 0.2s cubic-bezier(0.39, 0, 0.17, 0.99);

  --light-gray: #ebeff4;
  --dark-gray: #2b3445;
  --dark-gray-hover: #373f50;
  --icon-color: #7d879c;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

:focus {
  outline: none;
}

html,
body,
#app {
  height: 100%;
}

body {
  background: var(--background);
  font-family: var(--font);
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--text-color);
}

a {
  color: var(--accent);
  transition: var(--transition);
}

input[type='text'],
input[type='password'],
input[type='email'],
input[type='number'],
input[type='date'],
input[type='file'],
.input-file-label,
select,
textarea {
  background: #fff;
  font-family: var(--font);
  font-size: var(--font-size);
  display: flex;
  align-items: center;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  color: var(--text-color);
  border: 1px solid var(--border-color-muted);
  border-radius: var(--radius);
  transition: var(--transition);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.react-select-container .react-select__input {
  height: 30px;
}

.react-select-container .react-select__control {
  border: 1px solid var(--border-color-muted);
  border-radius: var(--radius);
  box-shadow: none;
}

.react-select-container .react-select__menu {
  z-index: 9999;
}

input:hover,
select:hover,
textarea:hover,
.react-select-container .react-select__control:hover {
  border-color: #000;
}

input:focus,
select:focus,
textarea:focus,
.react-select-container .react-select__control:focus {
  border-color: var(--accent);
}

input[readonly],
textarea[readonly] {
  cursor: default;
}

input.select__input {
  height: auto;
}

select {
  appearance: menulist-button;
  -webkit-appearance: menulist-button;
}

textarea {
  padding: 12px;
  min-height: 100px;
}

input[type='file'] {
  height: auto;
  padding: 10px 12px;
}

input[type='text']:disabled,
input[type='password']:disabled,
input[type='email']:disabled,
input[type='number']:disabled,
input[type='date']:disabled,
select:disabled,
textarea:disabled {
  opacity: 0.4;
  pointer-events: none;
}

button,
.btn {
  background: var(--primary);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  border: 1px solid var(--primary);
  border-radius: var(--radius);
  color: #fff;
  font-family: var(--font);
  font-size: var(--font-size);
  font-weight: 600;
  line-height: var(--line-height);
  text-align: center;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--transition);
}

button:hover,
.btn:hover {
  opacity: 0.9;
}

button:before,
.btn:before {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.6);
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
}

button:focus:before,
.btn:focus:before {
  transition: all 0.5s ease-out;
  opacity: 0;
  width: 160px;
  height: 160px;
  margin-top: -80px;
  margin-left: -80px;
}

button:disabled {
  opacity: 0.4;
  filter: grayscale(1);
  pointer-events: none;
}

.secondary-btn {
  background: #fff;
  color: var(--primary);
  border: 1px solid var(--primary);
}

.accent-btn {
  background: var(--accent);
  border: 1px solid var(--accent);
}
.accent-btn:hover {
  background: #2756b6;
  border-color: #2756b6;
}

.success-btn {
  background: var(--success);
  border: 1px solid var(--success);
}

.success-btn:hover {
  background: #2dbd2d;
  border-color: #2dbd2d;
}

.link-styled-text {
  background: none;
  padding: 0;
  border: 0;
  display: inline;
  font-size: inherit;
  font-weight: inherit;
  color: var(--accent);
  transition: var(--transition);
  text-decoration: underline;
  cursor: pointer;
}

.link-styled-text:hover {
  text-decoration: none;
}

h1 {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

h2 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 20px;
}

.wrapper {
  min-height: 100%;
  display: flex;
}

.main-column {
  flex-grow: 1;
  transition: var(--transition);
  display: flex;
  flex-direction: column;
  min-width: 0;
}

main {
  flex-grow: 1;
  padding: 20px;
  position: relative;
}

.form-item {
  margin-bottom: 20px;
  position: relative;
}

.form-item:last-child {
  margin-bottom: 0;
}

.form-item label {
  position: absolute;
  top: -8px;
  left: 5px;
  background: #fff;
  padding: 0 5px;
  font-size: 12px;
  color: var(--text-color-muted);
  transition: var(--transition);
  pointer-events: none;
  z-index: 2;
}

.form-item:hover label {
  color: #000;
}

.form-item:focus-within label {
  color: var(--accent);
}

.form-item.has-error label {
  color: var(--danger);
}

.form-field {
  position: relative;
}

.form-field .MuiIconButton-root {
  background: #fff;
  position: absolute;
  top: 50%;
  right: 3px;
  transform: translateY(-50%);
  overflow: hidden;
}

.form-field:has(.MuiIconButton-root) input {
  padding-right: 50px;
}

.form-field-pic {
  padding-top: 10px;
}

.form-field-pic img {
  max-height: 50px;
}

.form-error {
  color: var(--danger);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 10px;
}

.form-error:empty {
  margin-top: 0;
}

.form-success {
  color: var(--success);
  font-size: 12px;
  margin-top: 5px;
  margin-left: 10px;
}

.has-error .form-field input,
.has-error .form-field select,
.has-error .form-field textarea,
.has-error .select__control,
.has-error .react-select-container .react-select__control {
  border-color: var(--danger);
}

.form-submit {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.form-submit button {
  flex-grow: 1;
}

.not-found,
.forbidden-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  font-size: 20px;
  text-align: center;
  padding: 20px;
}

.not-found h1,
.forbidden-page h1 {
  font-size: 150px;
  line-height: 1.1;
  margin: 0;
}

.not-found h2,
.forbidden-page h2 {
  font-size: 44px;
  font-weight: 700;
}

@media (max-width: 780px) {
  body {
    font-size: 12px;
  }
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
  main {
    padding: 10px 10px;
  }
}

.animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
