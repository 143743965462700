.modal-confirm {
  text-align: center;
}

.modal-confirm__title {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}

.modal-confirm__message {
  margin-bottom: 20px;
}

.modal-confirm__btns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.modal-confirm__btns button {
  flex: 1;
}
