.pie-chart-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.pie-chart {
  transform: rotate(-90deg);
}

.pie-chart-fill {
  transition: stroke-dashoffset 1s ease;
}

.percentage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
