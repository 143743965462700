.loading-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-spinner .loading {
  position: relative;
  width: 40px;
  height: 40px;
  margin: auto;
}

.loading-spinner .loading div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 4px;
  border: 4px solid var(--accent);
  border-radius: 50%;
  animation: loading 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: var(--accent) transparent transparent transparent;
}

.loading-spinner .loading div:nth-child(1) {
  animation-delay: -0.45s;
}
.loading-spinner .loading div:nth-child(2) {
  animation-delay: -0.3s;
}
.loading-spinner .loading div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
