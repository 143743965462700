.action-btns {
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-btns button {
  background: none;
  width: 35px;
  height: 35px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--text-color-muted);
  text-indent: -9999px;
  border: none;
  border-radius: 50%;
}

.action-btns button svg {
  font-size: 18px;
  font-weight: 700;
}

.action-btns .btn-edit {
  color: #ff9800;
}

.action-btns .btn-approve,
.action-btns .btn-add {
  color: var(--success);
}

.action-btns .btn-refund,
.action-btns .btn-reject,
.action-btns .btn-delete {
  color: var(--danger);
}

.action-btns .btn-view {
  color: var(--accent);
}

.action-btns .btn-view-edit {
  color: var(--success);
}

.action-btns button.is-enabled:hover {
  background: rgba(0, 0, 0, 0.04);
}

.action-btns button.is-disabled {
  color: var(--text-color-muted);
  pointer-events: none;
}
