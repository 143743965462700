.input-monetary-container {
  position: relative;
  width: 100%;
  display: inline-flex;
  align-items: center;
}

.input-monetary {
  padding-right: 35px;
  -moz-appearance: textfield;
  appearance: textfield;
}

.input-monetary::-webkit-outer-spin-button,
.input-monetary::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.input-monetary-suffix {
  position: absolute;
  display: flex;
  right: 8px;
  pointer-events: none;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}
