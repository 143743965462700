.status-pending,
.status-approved,
.status-success,
.status-rejected,
.status-mismatch,
.status-invalid,
.status-failed,
.status-new,
.status-chargeback,
.status-cancelled,
.status-refunded,
.status-active,
.status-expired {
  font-size: 12px;
  font-weight: 600;
  padding: 3px 12px;
  border-radius: 8px;
  display: inline-block;
}

.status-pending,
.status-mismatch {
  background-color: #fff8e5;
  color: #d29a09;
}

.status-approved,
.status-success,
.status-active {
  background-color: #e7f9ed;
  color: #0cc148;
}

.status-rejected,
.status-invalid,
.status-failed,
.status-expired {
  background-color: #fce9ec;
  color: #c81432;
}

.status-new {
  background-color: #e7f5f9;
  color: #1512e4;
}

.status-chargeback,
.status-refunded {
  background-color: #d9d2e9;
  color: #634d97;
}

.status-cancelled {
  background-color: #ccc;
  color: #837f7f;
}

.status-pending::first-letter,
.status-approved::first-letter,
.status-success::first-letter,
.status-rejected::first-letter,
.status-mismatch::first-letter,
.status-invalid::first-letter,
.status-failed::first-letter,
.status-new::first-letter,
.status-chargeback::first-letter,
.status-cancelled::first-letter,
.status-refunded::first-letter,
.status-expired::first-letter {
  text-transform: uppercase;
}
