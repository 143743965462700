.table-control-menu-icon-button {
  width: 34px;
  height: 34px;
  padding: 5px;
  border: none;
  border-radius: 50%;
  background-color: white;
  color: #1976d2;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: rgba(25, 118, 210, 0.04);
  }
}
