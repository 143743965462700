.login-page {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.login-form-block {
  width: 100%;
  max-width: 500px;
  background: #fff;
  padding: 40px;
  box-shadow: 0px 8px 45px rgba(3, 0, 71, 0.09);
  border-radius: var(--radius);
}

.login-form-block h1 {
  text-align: center;
}

.login-form-title {
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .login-form-block {
    padding: 20px;
  }
}
