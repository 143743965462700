aside {
  background: var(--dark-gray);
  width: 85px;
  padding: 15px;
  color: var(--light-gray);
  transition: var(--transition);
  flex-shrink: 0;
  overflow-y: auto;
  position: sticky;
  top: 0;
  height: 100vh;
}

aside.sidebar__open {
  width: 270px;
}

aside.sidebar__open .sidebar-logo,
aside.sidebar__open .navlink-txt {
  display: block;
}
aside.sidebar__open .sidebar-logo {
  display: flex;
}
aside.sidebar__open .sidebar-header {
  justify-content: space-between;
}

.sidebar-header {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-logo {
  display: none;

  font-size: 22px;
  font-weight: 700;
  text-decoration: none;
  align-items: center;
  gap: 10px;
}

.paynoomo-logo {
  background: url('../../../assets/images/logo.svg') no-repeat center center / contain;
  width: 170px;
  height: 40px;
  text-indent: -9999px;
}

.lakhpay-logo:before {
  content: '';
  background: url('../../../assets/images/logo-lakhpay.svg') no-repeat left center / contain;
  width: 28px;
  height: 28px;
}

.toggle-sidebar-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.toggle-sidebar-btn:hover {
  background: var(--dark-gray-hover);
}

@media (max-width: 1280px) {
  aside {
    transform: translateX(-100%);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 10;
  }
  .toggle-sidebar-btn {
    display: none;
  }
  aside.sidebar-mob__open {
    transform: translateX(0);
  }
}
